import axios from 'axios'
import '../../axiosConfig'

class Usuarios {

    static SESSAO_NOME_CHAVE = "usuario";
    static ACAO_PERMITIDA = 1;

    static async listagem() {
        return await axios.get(`usuario/`)
    }
    static async adicionar(dados) {
        return await axios.post(`usuario/`, dados)
    }
    
    static async detalhes(usuario_id) {
        return await axios.get(`usuario/${usuario_id}/`)
    }
    
    static async atualizar(usuario_id, dados) {
        return await axios.put(`usuario/${usuario_id}/`, dados)
    }
    
    static async apagar(usuario_id) {
        return await axios.delete(`usuario/${usuario_id}/`)
    }

    static async login(dados) {
        return await axios.post(`login/`, dados)
    }

    static setDadosDaSessao(dados) {
        localStorage.setItem(Usuarios.SESSAO_NOME_CHAVE, dados)
    }

    static getDadosDaSessao() {
        return localStorage.getItem(Usuarios.SESSAO_NOME_CHAVE)
    }

    static getParsedDadosDaSessao() {
        try {
            return JSON.parse(this.getDadosDaSessao())
         }
         catch (e) {
            this.logout()
         }
    }

    static logout() {
        localStorage.removeItem(Usuarios.SESSAO_NOME_CHAVE);
    }

    static isLogado() {
        return Usuarios.getDadosDaSessao()
    }

    static canAdd() {
        Usuarios.redirectToLoginIfNotLogged()
        return parseInt(Usuarios.getParsedDadosDaSessao().rel_perfil_usuario.permissao_criacao) === Usuarios.ACAO_PERMITIDA ? true : false
    }
    
    static canUpdate() {
        Usuarios.redirectToLoginIfNotLogged()
        return parseInt(Usuarios.getParsedDadosDaSessao().rel_perfil_usuario.permissao_alteracao) === Usuarios.ACAO_PERMITIDA ? true : false
    }
    
    static canDelete() {
        Usuarios.redirectToLoginIfNotLogged()
        return parseInt(Usuarios.getParsedDadosDaSessao().rel_perfil_usuario.permissao_exclusao) === Usuarios.ACAO_PERMITIDA ? true : false
    }
    
    static canView() {
        Usuarios.redirectToLoginIfNotLogged()
        return parseInt(Usuarios.getParsedDadosDaSessao().rel_perfil_usuario.permissao_visualizacao) === Usuarios.ACAO_PERMITIDA ? true : false
    }
    
    static redirectToLoginIfNotLogged() {
        if(!Usuarios.isLogado()){
            window.location = "/"            
        }
    }
}

export default Usuarios
import React, { Suspense } from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
// material-ui
import CircularSpinner from 'components/UI/Spinner/CircularSpinner'

// Home
const Home = React.lazy(() => import('./components/Home/Home'));

// Login
const Login = React.lazy(() => import('./screens/Login/Login'));
const Logout = React.lazy(() => import('./screens/Login/Logout'));

// Eventos
const EventosLista = React.lazy(() => import('./screens/Eventos/EventosLista'));
const EventosAdicionar = React.lazy(() => import('./screens/Eventos/EventosAdicionar'));
const EventosEditar = React.lazy(() => import('./screens/Eventos/EventosEditar'));
const EventosDetalhes = React.lazy(() => import('./screens/Eventos/EventosDetalhes'));

// Usuários
const UsuariosLista = React.lazy(() => import('./screens/Usuarios/UsuariosLista'));
const UsuariosAdicionar = React.lazy(() => import('./screens/Usuarios/UsuariosAdicionar'));
const UsuariosEditar = React.lazy(() => import('./screens/Usuarios/UsuariosEditar'));

// Clientes
const ClientesLista = React.lazy(() => import('./screens/Clientes/ClientesLista'));
const ClientesAdicionar = React.lazy(() => import('./screens/Clientes/ClientesAdicionar'));
const ClientesEditar = React.lazy(() => import('./screens/Clientes/ClientesEditar'));
const ClientesDetalhes = React.lazy(() => import('./screens/Clientes/ClientesDetalhes'));

// Servicos
const ServicosLista = React.lazy(() => import('./screens/Servicos/ServicosLista'));
const ServicosAdicionar = React.lazy(() => import('./screens/Servicos/ServicosAdicionar'));
const ServicosEditar = React.lazy(() => import('./screens/Servicos/ServicosEditar'));

// Perfil
const PerfilLista = React.lazy(() => import('./screens/Perfil/PerfilLista'));
const PerfilAdicionar = React.lazy(() => import('./screens/Perfil/PerfilAdicionar'));
const PerfilEditar = React.lazy(() => import('./screens/Perfil/PerfilEditar'));

// Financeiro
const Financeiro = React.lazy(() => import('./screens/Financeiro/FinanceiroHome'));

// Recebimento
const RecebimentosLista = React.lazy(() => import('./screens/Recebimentos/RecebimentosLista'));
const RecebimentosAdicionar = React.lazy(() => import('./screens/Recebimentos/RecebimentosAdicionar'));
const RecebimentosEditar = React.lazy(() => import('./screens/Recebimentos/RecebimentosEditar'));
const RecebimentosDetalhes = React.lazy(() => import('./screens/Recebimentos/RecebimentosDetalhes'));
const RecebimentosParcelaEditar = React.lazy(() => import('./screens/Recebimentos/RecebimentosParcelaEditar'));

// Pagamento
const PagamentosLista = React.lazy(() => import('./screens/Pagamentos/PagamentosLista'));
const PagamentosAdicionar = React.lazy(() => import('./screens/Pagamentos/PagamentosAdicionar'));
const PagamentosEditar = React.lazy(() => import('./screens/Pagamentos/PagamentosEditar'));
const PagamentosDetalhes = React.lazy(() => import('./screens/Pagamentos/PagamentosDetalhes'));
const PagamentosParcelaEditar = React.lazy(() => import('./screens/Pagamentos/PagamentosParcelaEditar'));

// Contatos
const ContatosLista = React.lazy(() => import('./screens/Contatos/ContatosLista'));
const ContatosAdicionar = React.lazy(() => import('./screens/Contatos/ContatosAdicionar'));
const ContatosEditar = React.lazy(() => import('./screens/Contatos/ContatosEditar'));
const ContatosDetalhes = React.lazy(() => import('./screens/Contatos/ContatosDetalhes'));

const Dashboard = React.lazy(() => import('./screens/Dashboard/Dashboard'));


const App = props => {
    let routes = (
        <HashRouter basename={process.env.PUBLIC_URL}>
            <Route render={({ location }) => (
                <Switch location={location}>
                    <Route path="/" exact render={props => <Home {...props} />} />

                    {/* Login */ }
                    <Route path="/login" exact render={props => <Login {...props} />} />
                    {/* Logout */ }
                    <Route path="/logout" exact render={props => <Logout {...props} />} />
                    
                    {/* Eventos */ }
                    <Route path="/eventos-lista" exact render={props => <EventosLista {...props} />} />
                    <Route path="/eventos-adicionar" exact render={props => <EventosAdicionar {...props} />} />
                    <Route path="/eventos-editar/:id" exact render={props => <EventosEditar {...props} />} />
                    <Route path="/eventos-detalhes/:id" exact render={props => <EventosDetalhes {...props} />} />

                    {/* Usuários */ }
                    <Route path="/usuarios-lista" exact render={props => <UsuariosLista {...props} />} />
                    <Route path="/usuarios-adicionar" exact render={props => <UsuariosAdicionar {...props} />} />
                    <Route path="/usuarios-editar/:id" exact render={props => <UsuariosEditar {...props} />} />

                    {/* Clientes */ }
                    <Route path="/clientes-lista" exact render={props => <ClientesLista {...props} />} />
                    <Route path="/clientes-adicionar" exact render={props => <ClientesAdicionar {...props} />} />
                    <Route path="/clientes-editar/:id" exact render={props => <ClientesEditar {...props} />} />
                    <Route path="/clientes-detalhes/:id" exact render={props => <ClientesDetalhes {...props} />} />

                    {/* Servicos */}
                    <Route path="/servicos-lista" exact render={props => <ServicosLista {...props} />} />
                    <Route path="/servicos-adicionar" exact render={props => <ServicosAdicionar {...props} />} />
                    <Route path="/servicos-editar/:id" exact render={props => <ServicosEditar {...props} />} />

                    {/* Perfil */}
                    <Route path="/perfil-lista" exact render={props => <PerfilLista {...props} />} />
                    <Route path="/perfil-adicionar" exact render={props => <PerfilAdicionar {...props} />} />
                    <Route path="/perfil-editar/:id" exact render={props => <PerfilEditar {...props} />} />

                    {/* Financeiro */}
                    <Route path="/financeiro" exact render={props => <Financeiro {...props} />} />
                    
                    {/* Recebimentos */}
                    <Route path="/recebimentos-lista" exact render={props => <RecebimentosLista {...props} />} />
                    <Route path="/recebimentos-adicionar" exact render={props => <RecebimentosAdicionar {...props} />} />
                    <Route path="/recebimentos-editar/:id" exact render={props => <RecebimentosEditar {...props} />} />
                    <Route path="/recebimentos-detalhes/:id" exact render={props => <RecebimentosDetalhes {...props} />} />
                    <Route path="/recebimentos-detalhes/recebimentos-parcela-editar/:id_recebimento/:id" exact render={props => <RecebimentosParcelaEditar {...props} />} />

                    {/* Pagamentos */}
                    <Route path="/pagamentos-lista" exact render={props => <PagamentosLista {...props} />} />
                    <Route path="/pagamentos-adicionar" exact render={props => <PagamentosAdicionar {...props} />} />
                    <Route path="/pagamentos-editar/:id" exact render={props => <PagamentosEditar {...props} />} />
                    <Route path="/pagamentos-detalhes/:id" exact render={props => <PagamentosDetalhes {...props} />} />
                    <Route path="/pagamentos-detalhes/pagamentos-parcela-editar/:id_pagamento/:id" exact render={props => <PagamentosParcelaEditar {...props} />} />

                    {/* Contatos */ }
                    <Route path="/contatos-lista" exact render={props => <ContatosLista {...props} />} />
                    <Route path="/contatos-adicionar" exact render={props => <ContatosAdicionar {...props} />} />
                    <Route path="/contatos-editar/:id" exact render={props => <ContatosEditar {...props} />} />
                    <Route path="/contatos-detalhes/:id" exact render={props => <ContatosDetalhes {...props} />} />

                    <Route path="/dashboard" exact render={props => <Dashboard {...props} />} />

                    <Redirect to="/" />
                </Switch>
            )} />
        </HashRouter>
    );

    return (
        <div>
            <Suspense fallback={<CircularSpinner />}>{routes}</Suspense>
        </div>
    );
};

export default App;

import axios from 'axios';

import Usuarios from './classes/Usuarios/Usuarios'

axios.defaults.baseURL = 'http://18.222.232.17/:5000/api/v1/';

axios.interceptors.request.use(
    config => {
        let usuarioSessao = Usuarios.getDadosDaSessao();
        if (usuarioSessao) {
            usuarioSessao = JSON.parse(usuarioSessao)
            if (usuarioSessao.access_token) {
                config.headers['Authorization'] = 'Bearer ' + usuarioSessao.access_token;
            }
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        if (error.response.status === 401) {
            Usuarios.logout()
        }
        return Promise.reject(error.response);
    });
